// Import mixins
@import "assets/styles/mixins.scss";

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SELECT */

.ant-select {
  font-family: "Nunito Sans", sans-serif;
}

.ant-select-dropdown {
  border: 1px solid #e9e9e9;
  animation-duration: 0s !important;
}

.ant-select-auto-complete {
  ol,
  ul,
  dl {
    margin: 0;
  }
}
