// Import mixins
@import "assets/styles/mixins.scss";

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN LAYOUT */

.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #f0f2f5;
}

.ant-layout {
  min-height: 100vh;
  background: transparent;
}

.ant-layout-header {
  padding: 0;
}

.ant-layout-footer {
  padding: 0;
  background: transparent;
}

.ant-collapse {
  background: transparent;
}

.ant-layout-sider {
  transition: all 0.2s;
  background: #fff;
}

.ant-layout-sider-trigger {
  display: none !important;
}

.ant-layout-footer {
  position: relative;
}
