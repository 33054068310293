.input {
  flex: 1;
  flex-basis: calc(50% - 20px);
  padding-bottom: 10px;
  position: relative;
  color: #4a525f;

  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #9398a0;
    margin-bottom: 6px;

    .subLabel {
      font-weight: 400;
      font-size: 12px;
    }

    .required {
      color: #ff0000;
    }
  }

  .input-icon {
    float: right;
    position: absolute;
    margin-top: 8px;
    right: 8px;

    cursor: default;
  }

  .text-input,
  .status {
    padding-left: 10px;
    padding-right: 25px;
    width: 100%;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d7dce5;
    border-radius: 0.25rem;
    outline: none;
    color: #4a525f;
    font-size: 14px;
    font-weight: 400;
    cursor: default;
  }

  .text-area {
    resize: none;
    padding: 10px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #d7dce5;
    border-radius: 0.25rem;
    outline: none;
    color: #4a525f;
    font-size: 14px;
    font-weight: 400;
    cursor: default;
  }

  .text-input-custom:hover {
    border-color: #118bf0 !important;
  }

  .centerStatus {
    margin-top: 10px;
    color: #ffffff;
  }
}

.input-full {
  flex-basis: 100%;
}

textarea:focus,
input:focus {
  outline: none;
}

input:disabled {
  color: #4a525f !important;
  font-weight: 400 !important;
  opacity: 1;
  -webkit-text-fill-color: #4a525f !important;
}

.disabled {
  opacity: 0.7 !important;
}

@media only screen and (max-width: 450px) {
  .input {
    flex: unset;
    flex-basis: unset;
    width: 100%;
  }
}
