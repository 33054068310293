.vehicleInfoContainer {
  left: 120px;
  z-index: 9999999999;
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 20px;
  border: 1px solid rgb(224, 224, 224);
  min-width: 296px;
  border-radius: 8px;
  -webkit-box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.15);
}

.row {
  display: flex;
  margin-top: 10px;
  .bold {
    font-weight: 600;
  }
}

.row:first-child {
  margin-top: 0;
}

.img {
  width: 90px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
  border: solid 1px #d7dce5;

  img {
    border-radius: 5px;
    object-fit: cover;
    width: inherit;
    height: inherit;
  }
}
