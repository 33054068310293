.route-compare-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.route-compare-content {
  display: flex;
  position: relative;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-evenly;
  width: 90vw;
  height: 90vh;
  background-color: #fff;
  border-radius: 10px;
}

.map-container {
  margin: 20px;
  width: 80%;
  height: calc(100% - 20px);
  padding-top: 60px;
}

.routes-container {
  padding-top: 50px;
  height: 85vh;
  overflow: auto;
  width: 30%;
  float: right;
}

.map-routes-container {
  display: contents;
}

.map-description {
  padding: 2.2% 25% 1% 0;
  font-size: 14px;
  background-color: white;
  margin: 0 20px;
}

.map-description > span {
  text-align: left;
}

.routes-title {
  align-self: baseline;
  border-radius: 10px;
  display: block;
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 100%;
}

.routes-title > span > button > span > svg {
  font-size: 20px;
  cursor: pointer;
  line-height: 35px;
  height: 25px;
  width: 25px;
  fill: rgba(0, 0, 0, 0.54);
  vertical-align: middle;
  margin: 2px 5px;

  border-radius: 50%;
}

.routes-title > span {
  float: right;
  cursor: pointer;
}

input[type='checkbox'] {
  height: 18px;
  width: 18px;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #98a6ad;
  border-radius: 3px;
}

input[type='checkbox']:checked {
  border: none;
}

.checkbox-container > input[type='checkbox']:checked + label::after {
  color: #ffffff !important;
  content: '\f00c';
  font-family: 'FontAwesome';
  background-color: #118bf0 !important;
  border-color: #118bf0 !important;
  border: 2px solid #118bf0 !important;
  border-radius: 3px;
}

.selected::after {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  border: 2px solid #6c757d !important;
}

.ant-select-lg .ant-select-selection--multiple {
  min-height: 40px;
  border: 1px solid rgb(207, 207, 207);
  box-shadow: none;
  max-height: 75px;
  overflow: auto;
}

.ant-select-selection__placeholder {
  color: rgb(147, 152, 162);
  font-size: 14px;
}

.checkbox-label {
  height: 18px;
  width: 18px;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  top: 0;
  border-radius: 3px;
}

.checkbox-label::before {
  background-color: #ffffff;
  border-radius: 3px;
  content: '';
  display: inline-block;
  height: 17px;
  left: 0;
  top: 0;
  margin-left: -17px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 17px;
  outline: none !important;
}

.routes-side-header {
  display: block;
  position: sticky;
  top: 0;
  padding: 0 14px 30px 14px;
  background-color: white;
  z-index: 999999;
}

.routes-side-title {
  font-weight: bold !important;
  white-space: normal;
  word-wrap: break-word;
  display: block;
  color: rgba(0, 0, 0, 0.65);
}

.routes-side-title-container {
  margin-bottom: 20px;
  padding-left: 38px;
  color: rgba(0, 0, 0, 0.54);
}

@media (max-width: 768px) {
  .map-container {
    max-height: 30%;
    margin-top: 40px;
    padding-top: 0;
    width: initial;
    height: 100%;
  }

  .routes-container {
    height: 65%;
    width: 100%;
    float: none;
    padding: 15px;
    padding-top: 0;
  }

  .map-routes-container {
    display: block;
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  .map-description {
    padding: 5% 5% 0px 0px;
    margin: 5px 20px;
  }

  .routes-side-header {
    padding: 0 14px 14px 14px;
  }
}
