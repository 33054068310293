.searched {
  z-index: 100 !important;
}
.trip__schedule * .item.hour {
  left: -21px !important;
  padding-left: 10px;
}

.MuiPopover-root {
  z-index: 999999 !important;
}

em {
  font-style: normal !important;
}

.MuiInputBase-root:before {
  border-bottom: none !important;
}
