// Import mixins
@import "assets/styles/mixins.scss";

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SPIN */

.ant-spin-blur {
  filter: none !important;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: none;
}
