// Import mixins
@import "assets/styles/mixins.scss";

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN BACKTOP */

.ant-back-top {
  right: rem(50);
  bottom: rem(50);
}

.ant-back-top-content {
  border-radius: 5px;
  background: $gray;

  &:hover {
    background: $gray-darken;
  }
}
