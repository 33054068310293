.modal-content {
  width: 600px;
}

span.clock-icon {
  margin-top: 0 !important;
}

.icon {
  fill: #118bf0 !important;
  width: 22px !important;
  height: 22px !important;
  margin-right: 1px;
}

.time-inputs-container {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media only screen and (max-width: 767px) {
  .modal-content {
    width: calc(100vw - 16px);
  }

  .time-inputs-container {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
}
