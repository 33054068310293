.booked {
  background: #009886;
  background-color: #009886;
}

.dispatched {
  background: #f76397;
  background-color: #f76397;
}

.en-route,
.on-route {
  background: #ffaa00;
  background-color: #ffaa00;
}

.arrived {
  background: #ee6600 !important;
  background-color: #ee6600 !important;
}

.arriveddo {
  background: #3bafda !important;
  background-color: #3bafda !important;
}

.pick-up,
.pickup {
  color: #fff !important;
  background: #3ddcf7;
  background-color: #3ddcf7;
}

.completed {
  color: #4a525f !important;
  background: #d7dce5 !important;
  background-color: #d7dce5 !important;
}

.cancelled {
  color: #fff !important;
  background: #9398a0 !important;
  background-color: #9398a0 !important;
}

.canceled {
  color: #fff !important;
  background: #9398a0 !important;
  background-color: #9398a0 !important;
}

.timeblockstatus {
  color: #4a525f !important;
  background-color: #e3e3e3 !important;
  opacity: 1;
  background-image: radial-gradient(#ffffff 1.1500000000000001px, #e3e3e3 1.1500000000000001px);
  background-size: 23px 23px;
}

.break {
  color: #4a525f;
  border: 1px solid #dfe2e6 !important;
  /* background-color: #ffffff !important; */
  opacity: 1;
  background: repeating-linear-gradient(45deg, #cdcdcd, #cdcdcd 3.5px, #ffffff 3.5px, #ffffff 17.5px);
}

.driverActionStatus {
  color: #4a525f;
  border: 1px solid #d0d0d0 !important;
  opacity: 1;
  background-position: 7px 0, 7px 0, 0 0, 0 0;
  background-size: 7px 7px;
  background-repeat: repeat;
  /* color: #4a525f !important;
  border: 1px solid #d0d0d0 !important;
  background-color: #ffffff;
  opacity:1;
  background-image:  linear-gradient(135deg, #dbdbdb 25%, transparent 25%), linear-gradient(225deg, #dbdbdb 25%, transparent 25%), linear-gradient(45deg, #dbdbdb 25%, transparent 25%), linear-gradient(315deg, #dbdbdb 25%, #ffffff 25%);
  background-position:  8px 0, 8px 0, 0 0, 0 0;
  background-size: 8px 8px;
  background-repeat: repeat; */
}

.driverActionStatus-canceled {
  color: #fff !important;
  background-image: linear-gradient(135deg, #e1e1e1 25%, transparent 25%),
    linear-gradient(225deg, #e1e1e1 25%, transparent 25%), linear-gradient(45deg, #e1e1e1 25%, transparent 25%),
    linear-gradient(315deg, #e1e1e1 25%, #9398a0 25%);
}

.driverActionStatus-no-show {
  color: #4a525f;
  border: 1px solid #d0d0d0;
  background-image: linear-gradient(135deg, #e1e1e1 25%, transparent 25%),
    linear-gradient(225deg, #e1e1e1 25%, transparent 25%), linear-gradient(45deg, #e1e1e1 25%, transparent 25%),
    linear-gradient(315deg, #e1e1e1 25%, #805580 25%);
}

.driverActionStatus-booked {
  color: #4a525f !important;
  background-image: linear-gradient(135deg, #e1e1e1 25%, transparent 25%),
    linear-gradient(225deg, #e1e1e1 25%, transparent 25%), linear-gradient(45deg, #e1e1e1 25%, transparent 25%),
    linear-gradient(315deg, #e1e1e1 25%, #009886 25%);
}

.driverActionStatus-dispatched {
  color: #4a525f !important;

  background-image: radial-gradient(ellipse farthest-corner at 17px 17px, #f76397, #f76397 50%, #fff 50%);
  background-size: 17px 17px;
}

.driverActionStatus-en-route {
  color: #4a525f !important;

  background-image: radial-gradient(ellipse farthest-corner at 17px 17px, #ffaa00, #ffaa00 50%, #fff 50%);
  background-size: 17px 17px;
}

.driverActionStatus-on-route {
  color: #4a525f !important;

  background-image: radial-gradient(ellipse farthest-corner at 17px 17px, #ffaa00, #ffaa00 50%, #fff 50%);
  background-size: 17px 17px;
}

.driverActionStatus-arrived {
  color: #4a525f !important;

  background-image: radial-gradient(ellipse farthest-corner at 17px 17px, #ee6600, #ee6600 50%, #fff 50%);
  background-size: 17px 17px;
}

.driverActionStatus-pick-up {
  color: #4a525f !important;

  background-image: radial-gradient(ellipse farthest-corner at 17px 17px, #3ddcf7, #3ddcf7 50%, #fff 50%);
  background-size: 17px 17px;
}

.driverActionStatus-pickup {
  color: #4a525f !important;
  background-image: radial-gradient(ellipse farthest-corner at 17px 17px, #3ddcf7, #3ddcf7 50%, #fff 50%);
  background-size: 17px 17px;
}

.driverActionStatus-completed {
  color: #4a525f !important;

  background-image: radial-gradient(ellipse farthest-corner at 17px 17px, #d7dce5, #d7dce5 50%, #ffffff 50%) !important;
  background-size: 17px 17px !important;
}

.driverActionStatus-cancelled {
  color: #fff !important;

  background-image: radial-gradient(ellipse farthest-corner at 17px 17px, #4a525f, #4a525f 50%, #fff 50%);
  background-size: 17px 17px;
}

.driverActionStatus-canceled {
  color: #fff !important;

  background-image: radial-gradient(ellipse farthest-corner at 17px 17px, #4a525f, #4a525f 50%, #fff 50%);
  background-size: 17px 17px;
}

.driverActionStatus-no-show {
  color: #4a525f !important;

  background-image: radial-gradient(ellipse farthest-corner at 17px 17px, #805580, #805580 50%, #fff 50%);
  background-size: 17px 17px;
}

.driverActionStatus-cancelled-by-job {
  color: #fff !important;
  background-image: radial-gradient(ellipse farthest-corner at 17px 17px, #4a525f, #4a525f 50%, #fff 50%);
  background-size: 17px 17px;
}

.break-canceled {
  color: #fff !important;
  background: #9398a0 !important;
  background-color: #9398a0 !important;
  opacity: 1;
  background: repeating-linear-gradient(45deg, #ffffff, #ffffff 3.5px, #9398a0 3.5px, #9398a0 17.5px);
}

.break-no-show {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #805580, #805580 3.5px, #f1f1f1 3.5px, #f1f1f1 17.5px);
}

.break-booked {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #009886, #009886 3.5px, #f1f1f1 3.5px, #f1f1f1 17.5px);
}

.break-dispatched {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #f76397, #f76397 3.5px, #f1f1f1 3.5px, #f1f1f1 17.5px);
}

.break-en-route {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #ffaa00, #ffaa00 3.5px, #f1f1f1 3.5px, #f1f1f1 17.5px);
}
.break-on-route {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #ffaa00, #ffaa00 3.5px, #f1f1f1 3.5px, #f1f1f1 17.5px);
}

.break-arrived {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #ee6600, #ee6600 3.5px, #f1f1f1 3.5px, #f1f1f1 17.5px);
}

.break-pick-up {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #3ddcf7, #3ddcf7 3.5px, #f1f1f1 3.5px, #f1f1f1 17.5px);
}

.break-pickup {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #3ddcf7, #3ddcf7 3.5px, #f1f1f1 3.5px, #f1f1f1 17.5px);
}

.break-completed {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #d7dce5, #d7dce5 3.5px, #fff 3.5px, #fff 17.5px) !important;
}

.break-cancelled {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #fff, #fff 3.5px, #f1f1f1 3.5px, #f1f1f1 17.5px);
}

.break-canceled {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #fff, #fff 3.5px, #f1f1f1 3.5px, #f1f1f1 17.5px);
}

.break-no-show {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #805580, #805580 3.5px, #f1f1f1 3.5px, #f1f1f1 17.5px);
}

.break-cancelled-by-job {
  color: #4a525f !important;
  background: repeating-linear-gradient(45deg, #fff, #fff 3.5px, #f1f1f1 3.5px, #f1f1f1 17.5px);
}

.canceled {
  color: #fff !important;
  background: #9398a0 !important;
  background-color: #9398a0 !important;
}

.no-show {
  background: #805580;
  background-color: #805580;
}

.booked-text {
  color: #009886 !important;
}

.dispatched-text {
  color: #f76397 !important;
}

.arriveddo-text {
  color: #3bafda !important;
}

.en-route-text {
  color: #ffaa00 !important;
}
.on-route-text {
  color: #ffaa00 !important;
}

.arrived-text {
  color: #ee6600 !important;
}

.pick-up-text {
  color: #3ddcf7 !important;
}

.pickup-text {
  color: #3ddcf7 !important;
}

.completed-text {
  color: #d7dce5 !important;
}

.cancelled-text {
  color: #fff !important;
}

.canceled-text {
  color: #fff !important;
}

.no-show-text {
  color: #805580 !important;
}

.react-datepicker-popper {
  z-index: 20 !important;
}

.cancelled-by-job {
  color: #fff !important;
}
