@import "assets/styles/Antd/antd.cleanui";
@import "assets/styles/Nprogress/nprogress.cleanui";
@import "assets/styles/Rcdrawer/rcdrawer.cleanui";
@import "assets/styles/Bootstrap/reset";
@import "assets/styles/mixins.scss";

button.gm-ui-hover-effect {
  display: none !important;
}

// @media (max-width : $md-max-width) and (max-height : $xs-max-width) {
@media (max-width: $md-max-width) {
  .gm-bundled-control-on-bottom {
    right: 30px !important;
    bottom: 116px !important;
  }
  .gm-bundled-control-on-bottom > div:first-child {
    top: 44px !important;
  }
  .gmnoprint > .gmnoprint > div {
    height: 44px !important;
    width: 22px !important;
  }
  .gmnoprint > .gmnoprint > div > div {
    /* seperator */
    width: 22.5px !important;
    margin: 0px 3.75px !important;
  }
  .gmnoprint > .gmnoprint button {
    width: 22px !important;
    height: 22px !important;
  }
  .gmnoprint > .gmnoprint button img {
    height: 11.5px !important;
    width: 11.5px !important;
    // margin: 6.75px 8.25px 9.75px !important;
  }

  // div.gm-style-iw-t {
  //   right: 6px !important;
  // }

  // div.gm-style-iw.gm-style-iw-c {
  //   // margin-left: -6px !important;
  // }

  // car icon
  // div.gm-style > div >div> div> div > img {
  //   width: 20px !important;
  //   height: 30px !important;
  //   margin-top: 10px !important;
  // }
}
