.right-click-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999999;
}

.right-click-contents {
  position: relative;
  height: inherit;
  width: inherit;
}

.right-click-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e9e8e8;
  border-radius: 8px;
  text-align: left;
  font-size: 13px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.right-click-menu-item {
  font-size: 13px;
  padding: 5px 10px;
  color:#4a525f;
}
.right-click-menu-item:first-child  {
  border-radius: 8px 8px 0 0;
  padding-top: 10px;
}

.right-click-menu-item:last-child {
  padding-top: 5px;
  border-radius: 0 0 8px 8px;
  padding-bottom: 10px;
}

.right-click-menu-item:hover {
  background-color: #e9e8e8;
}

.driver-working-hours {
  border: 1px solid #ffffff;
  position:absolute;
  z-index: 99;  width: 194px;
  border-radius:  10px ;
  padding: 5px;
  margin: 1px;
  color: #4a525f;
  margin-left: 2px;
  background-color: rgba(240, 242, 244, 0.7);
  border:2px solid #f0f2f4;
}

.drivers {
  padding-top:30px;
  max-height: 400px;
  overflow-y: auto;
}


.driver {
  padding-right: 10px;
}

.driver-working-hours-top {
  z-index: 101;
  opacity: 1;
  background-color: rgb(240, 242, 244);
}

.driver-working-hours > button {
  float: left;
}

.edit-modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(74, 82, 95, 0.4);
}


.edit-modal-schedules {
  display: flex;
  justify-content: space-between;
  position: relative;
  gap: 20px;
}

.portal {
  width: 100%;
  position: relative;
  padding-top: 10px;
}

.portal-time-block {
  position: relative;
  margin-top: 26px;
}

.portal-time-block > div:first-child {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;


  color: #9398A0;
  padding-bottom: 5px;
}

.timeblock-title,
.driver-action-title {
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 26px !important;
  
  color: #4A525F;
}

.notes {
  font-size: 18px !important;
}

.select-label {
  font-size: 13px;
  font-weight: normal;
}

.select-driver-dropdown {
  border-color:#b6b9bf;
  font-size: 13px;
  /* font-weight: bold; */
  padding: 5px;
  background: #fff;
  cursor: pointer;
  width: 100%;
  border-radius: 3px;
}




.edit-modal-container .timeblock input[type='text'] {
  width: 100%;
}

.edit-modal-container .react-datepicker-popper {
  transform:  unset !important;
  position: absolute !important;
  top: auto !important;
  left: auto !important;
}

.edit-modal-content .react-datepicker {

  border-color:  rgba(74, 82, 95, 0.4);
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.edit-modal-content .react-datepicker__header {

  color: #4A525F !important;
}

.edit-modal-content .react-datepicker-time__header {
  color: #4A525F !important;
}

.edit-modal-content .react-datepicker__triangle {
  transform:  unset !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 100;
}

.edit-modal-content .react-datepicker__triangle:before {
  transform:  unset !important;
  left: 0 !important;
  right: 0 !important;
  left: 20px !important;
  border-bottom-color:#aeaeae !important;
}

.edit-modal-content .react-datepicker__triangle:after {
  transform:  unset !important;
  right: 0 !important;
  left: 0 !important;
  left: 20px !important;
  border-bottom-color:#aeaeae !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: rgb(17, 139, 240) !important;
}
/* react date picker override */

.driver > div:nth-child(2) {
  padding-top: 41px;
}

.driver-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}

.edit-modal-driver {
  color: #4A525F;
  font-style: normal;

}

.edit-modal-shift {
  margin-top: 10px;
  color: #4A525F;
  font-size: 16px;
}



.minimized {
  height: 35px !important;
  overflow: hidden;
}

.minimize-btn, .setting-btn {
  cursor: pointer;
  border: 0;
  background: none;
  box-shadow: none;
}

.setting-btn {
  float: right !important;
}

.update-buttons {
  margin-top: 50px;
  text-align: right;
}

.update-buttons > button {
  min-width: 80px;
}

.ant-notification  {
  z-index: 999999 !important;
}



.text-area {
  resize: none;
  padding: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d7dce5;
  border-radius: 5px;
  outline: none;
  color: #4a525f;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.auto-size {
  max-width: 600px !important; 
}

.team-name {
  padding-top: 41px;
  font-size: 22px;
}

.update-buttons > button:first-child {
  float:left;
}