.modalContainer {
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.253);
  overflow-y: auto;

  .modal {
    position: relative;
    display: block;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 8px;
    height: 100vh;
    width: 100vw;
    padding-bottom: 30px;
    margin: 0 40px;

    .modalContent {
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      vertical-align: middle;
      align-self: center;
    }
  }

  .tripReorderContainer {
    margin-top: 50px;
    width: 1280px;
    max-width: 1280px;
    background: #fff;
    padding: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    animation: slideIn 0.3s ease-in;
    gap: 38px;
    text-align: left;
    align-items: stretch;
  }

  .mapContainer {
    min-width: 584px;
    height: inherit;
    border-radius: 8px;
  }

  .inputColor {
    color: #007bff;
    font-weight: 600;
  }

  .tripsContainer {
    background-color: #fff;
    color: #4a525f;
    font-style: normal;
    line-height: normal;
    position: relative;
    min-height: 400px;

    .tripReorder {
      p {
        font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
          'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 14px;
        line-height: 20px;
      }

      .tripItem:active {
        cursor: grabbing;
        opacity: 0.8 !important;
      }

      .removeIcon {
        cursor: pointer;
        margin-right: 0px;
      }

      .grabIcon {
        cursor: grab;
      }

      .optimizeBtn,
      .lockRouteBtn {
        margin-top: 10px;
        padding: 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }

      .optimizeBtn {
        margin-right: 10px;
      }
    }
  }

  .badge {
    min-width: 70px;
  }

  .header {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;

    .closeIcon {
      margin-top: -40px;
      margin-right: -40px;
    }
  }

  .subHeader {
    font-size: 18px;
    font-weight: 700;
  }

  .paragraph {
    font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
      'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    line-height: 20px;
  }

  .paragraph2 {
    color: #9398a0;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .optimizeTime {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    .pickupTime {
      align-self: center;
      width: 15%;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .tripItem {
      width: 85%;
    }
    .tripItem:active {
      cursor: grabbing;
      opacity: 1 !important;
    }
  }

  .tripList {
    font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
      'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    line-height: 20px;
    border-radius: 10px;
    border: 1px solid rgba(215, 220, 229, 0.5);
    list-style: none;
    padding: 20px;
  }

  .tripList > li:last-child {
    margin-bottom: 0;
  }

  .noDrag {
    cursor: pointer !important;
  }

  .tripItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f8fa;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    cursor: grab;
  }

  .tripItem:active {
    cursor: grabbing;
    opacity: 0.8 !important;
  }

  .tripIcon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

  .tripName {
    font-size: 16px;
    font-weight: 500;
  }

  .time {
    white-space: nowrap;
    font-size: 16px;
    font-weight: 500;
  }

  .tripDetails {
    flex-grow: 1;
    color: #4a525f;
    font-family: Roboto;
    font-style: normal;
    line-height: normal;

    .tripAddress {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.sameAddress {
  color: #9398a0;

  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}

.inputsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  // flex-wrap: wrap;

  > div:first-child {
    max-width: calc(50% - 20px);
  }
  > button {
    margin-top: 10px;
    min-width: 50%;
  }
}

.optimizeButtons {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.mapContainerArea {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.textError {
  color: red;
}

@keyframes slideIn {
  from {
    opacity: 0.5;
    margin-top: -100px;
  }
  50% {
    opacity: 0.7;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .mobileViewHeader {
    display: flex;
    justify-content: flex-start;
    padding-left: 0 !important;
    span {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
  .closeIcon {
    position: fixed !important;
    top: -2px !important;
    right: -2px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .modal {
    margin: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
    .modalContent {
      justify-content: flex-start !important;
    }
  }
  .mapContainer {
    padding-top: 10px !important;
    min-width: 100% !important;
    width: 100% !important;
    min-height: 400px !important;
  }
  .tripReorderContainer {
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 30px !important;
    overflow-x: auto !important;
    flex-wrap: wrap !important;

    .tripsContainer {
      min-height: 560px !important;
    }
  }
  .inputsContainer {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    > div,
    button {
      max-width: 100% !important;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1112px) {
  .modal {
    margin: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
    .modalContent {
      justify-content: flex-start !important;
    }
  }
  .tripReorderContainer {
    border-radius: 0 !important;
    margin: 0 !important;
    overflow-x: auto !important;
  }
  .mapContainer {
    min-width: 60% !important;
  }
  .inputsContainer {
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
    > div,
    button {
      max-width: 100% !important;
      width: 100%;
    }
  }
}
