
.update__booking__time--modal {
  top: 0;
  left: 0;
  font-size: 16px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(230, 230, 230, 0.4);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.update__booking__time--content {
  text-align: center;
  width: 500px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);

}

.update__booking__time--header {
  margin: 20px;
}

.update__booking__time--inputs {
  margin: 20px;
  text-align: left;
}


.update__booking__time--buttons {
  width: 100%;
  padding: 20px;
}

.update__booking__time--buttons > .right{
  display: inline-block;
  text-align: right;
  width: 60%;
}

.right-padding > button:first-child{
  margin-right: 10px;
}

.update__booking__time--buttons > .left{
  display: inline-block;
  text-align: left;
  width: 40%;
}