.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1003;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
  background: #000000;
  opacity: 0.5;
}

.modal-dialog-container {
  width: 100%;
  height: auto;
  overflow: unset;
  margin: auto;
}

.modal-dialog {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 0;
  overflow: unset;

  .circle-close-section {
    width: 100%;
    min-height: 140px;
    height: 140px;
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .circle-close-icon {
    font-size: 50px !important;
    cursor: pointer;
  }
}

.modal-section {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-x: auto;
  padding-bottom: 0;
  align-items: center;
}

.modal-content {
  width: 660px;
  height: fit-content;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
}

.modal-content-2 {
  margin: 0;
  padding: 50px 30px;
  font-size: 22px;
  color: #4a525f;
}

.modal-icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.modal-buttons-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;

  .second-button {
    margin-left: 10px;
  }
}

.buttons-full {
  justify-content: space-between;
}

.button {
  height: 42px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.icon {
  font-size: 57px !important;
  fill: none !important;
}

@media only screen and (max-width: 767px) {
  .modal-dialog-container {
    margin: unset;
  }

  .modal-content-container {
    padding-top: 0;
    padding-bottom: 140px;
  }

  .modal-content {
    margin-left: 0;
    max-height: none;
    overflow-y: visible;
    width: calc(100vw - 16px);
  }

  .modal-dialog {
    padding: 0;

    .circle-close-section {
      display: flex;
    }

    .modal-section {
      align-items: flex-start;
      overflow-x: unset;
    }
  }
}

@media only screen and (max-width: 640px) {
  .modal-content {
    width: calc(100vw - 16px);
  }
}

.custom-input {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #4a525f;
  height: 40px;
}

.custom-label {
  color: #9398a0;
}

.custom-label-container {
  height: 16px;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 6px;
}

.modal-triple-buttons-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  .row {
    display: flex;
    flex-direction: row;
  }

  .row.ar {
    width: 100%;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 640px) {
  .modal-buttons-section {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: stretch;

    .first-button {
      margin: 12px 0 0 0;
      width: 100%;
    }

    .second-button {
      margin: 0;
      width: 100%;
    }
  }

  .modal-buttons-section.mobile-single-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .first-button {
      margin: 0;
      width: 100%;
    }

    .second-button {
      margin: 0 0 0 12px;
      width: 100%;
    }
  }

  .modal-triple-buttons-section {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    .first-button {
      margin: 0;
      width: 100%;
    }

    .second-button {
      margin: 0 0 0 12px;
      width: 100%;
    }

    .third-button {
      margin: 0 0 12px 0;
      width: 100%;
    }
  }

  .modal-triple-buttons-section.single-column-mobile {
    .first-button {
      margin: 12px 0 0 0;
      width: 100%;
    }

    .second-button {
      margin: 12px 0 0 0;
      width: 100%;
    }

    .third-button {
      margin: 0;
      width: 100%;
    }
  }
}
