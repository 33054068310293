.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.7);
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__content {
  width: 700px;
  height: auto;
  max-height: calc(100vh - 40px);
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  overflow-y: auto;
}

.close__container {
  float: right;
  margin-right: -10x;
  margin-top: -10px;

  > img {
    margin-right: -20px;
    margin-top: -20px;

    cursor: pointer;
  }
}

.heading {
  text-align: center;
  color: #4a525f;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
}

.message {
  text-align: justify;
  color: #9398a0;
  font-weight: normal;
  font-size: 14px;
  padding-bottom: 40px;
}

.toggle {
  text-align: right;
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 18px;
  input[type='radio'] {
    appearance: none;
    width: 17px;
    height: 17px;
    margin: auto -14px;
    border: 2px solid #118bf0;
    border-radius: 50%;
  }
  input[type='radio'] + label {
    color: #4a525f;
    margin-left: 7px;
    font-weight: inherit;
    padding-left: 4px;
  }
  input[type='radio']:checked {
    appearance: auto;
  }
}

.map__container {
  position: relative;
  width: 100%;
  height: 100%;
}

.heat__map_container {
  display: block;
  width: 100%;
  height: 100%;
}

.google_map {
  width: 100%;
  height: 595px;
}

.polygon__label {
  font-weight: bolder;
  font-size: 18px;
  color: #000;
  text-shadow: 2px 3px 5px rgba(255, 255, 255, 0.7);
}
@media only screen and (max-width: 540px) {
  .modal__content {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }
}

.RadioLabel {
  font-size: 16px !important;
  line-height: 18.33px !important;
  color: #4a525f;
  font-weight: inherit;
  cursor: pointer;
}
