.booking__details--overlay {
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  left: 0;
  top: 0;
  position: fixed;
  /* display: flex; */
  justify-content: center;
  /* align-items: center; */
  background-color: rgba(230, 230, 230, 0.4);
  overflow: auto;
}

.booking__details_view {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.booking_details_container {
  background: #fff;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  min-width: 500px;
  max-width: 500px;
}

.booking__details--modals {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  display: block;

  width: auto;
  height: auto;

  position: relative;
}

.booking__account_view {
  max-width: 700px;
  padding: 20px;
  box-shadow: unset;
  min-width: 100%;
  background: none;
}

@media only screen and (max-width: 499px) {
  .booking__details--modals {
    min-width: unset;
  }
}

.booking__details--header {
  text-align: right;
}

.booking__details--header > span > button > span i {
  cursor: pointer;
  line-height: 26px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.54);
  padding: 2px 5px;
  border-radius: 50%;
}

.booking__details--header > span svg {
  cursor: pointer;
  line-height: 35px;
  height: 25px;
  width: 25px;
  fill: rgba(0, 0, 0, 0.54);
  vertical-align: middle;
  margin: 2px 5px;

  border-radius: 50%;
}

.booking__details--header > span:nth-child(4),
.btnBorderRight {
  display: inline-block;
  border-right: 1px solid #b1b0b0;
  line-height: 14px;
  margin: 4px;
}

.booking__details--content {
  display: block;
  margin: 0 20px 20px 20px;
  line-height: 22px;
  font-size: 13px;
}

.booking__details--content > div > i {
  font-size: 15px !important;
  color: rgb(10, 84, 144);
  padding-right: 5px;
}
.viewBookingIcon {
  cursor: pointer;
  color: #118bf0;
}
@media only screen and (max-width: 500px) {
  .booking_details_container {
    border-radius: 15px;
    min-width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    min-height: 100vh;
    overflow-y: auto;
    border-radius: 0;
  }
}

@media only screen and (max-height: 400px) {
  .booking_details_container {
    max-height: 100%;
    overflow-y: auto;
    border-radius: 0;
  }
}
