.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.btn:focus {
  box-shadow: none;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f5f5f5;
}
