.App {
  width: 100%;
  height: 100%;
  font-size: 10px;
  padding: 0;
  margin: 0;
  overflow: auto;
  background: #fff;
  z-index: 9;
}

.child {
  position: absolute;
  top: 150px;
  height: 100px;
  width: 100px;
  background-color: blue;
}

.main {
  display: flex;
  flex-direction: column;
  margin: 5px 5px;
  width: fit-content;
  height: auto;
  align-items: stretch;
}

/* .loading {
  position: fixed;
  z-index: 99999;
  width: 100vw !important;
  height: 100vh;
  top:0;
  left: 0;
  width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
}
.loading >div {
  margin-top: 100px;
} */

.add-driver {
  cursor: pointer;
  color: #118bf0;
}

.hours {
  overflow: visible;
  width: inherit;
  height: 200px;
  border-bottom: 1px solid rgb(224 224 224);
}

.hours__item {
  position: relative;
  height: inherit;
  display: flex;
  flex-direction: row;
}

.hours__item-titles {
  height: 80px;
}

.hour__time-and-driver {
  height: 0;
}

/* the vehicle and drivers row */
.hours__sticky {
  top: 0;
  height: 80px;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  background-color: #fff;
  z-index: 102;
  overflow: visible;
  width: inherit;
}

.hours-background {
  width: 70px;
  /* height: calc(100% - 15px); */
  position: absolute;
  z-index: 101;
  background: #fff;
  margin-left: -5px;
  padding: 0;
}

.item,
.item-header {
  overflow: hidden;
  min-width: 198px;
  height: 100%;
  font-size: 13px;
  cursor: pointer;
}

.item-header > span:first-child {
  color: rgb(157, 163, 172);
}

.item.hour {
  margin-top: -9px;
  font-size: 0.7rem;
  left: 0 !important;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  z-index: 101;
  min-width: 70px;
  background: rgba(255, 255, 255, 0.9);
}

.item:not(:first-child) {
  background: #fff;
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  border-left: 1px solid rgb(224 224 224);
}

.item:last-child {
  border-right: 1px solid rgb(224 224 224);
}

.item-header {
  display: inline-block;
  text-align: center !important;
  border-bottom: 1px solid rgb(224 224 224);
  background-color: #fff;
  font-size: 13px;
  position: relative;
  overflow: visible;
}

.item-header:not(:first-child) {
  border-left: 1px solid rgb(224 224 224);
}

.item-header:last-child {
  border-right: 1px solid rgb(224 224 224);
}

.item-header > span:first-child {
  float: left;
  padding-left: 5px;
}

.item-header > span {
  float: right;
  padding-right: 5px;
}

/* header for hours column */
.item-header:first-child {
  min-width: 70px;
}

.driver-name-container,
.update-driver {
  padding: 0 5px 0 10px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 180px;
  text-overflow: ellipsis;
}

.update-shuttle,
.update-driver {
  cursor: pointer;
  color: #118bf0;
}

.hour-now,
.time-now {
  position: absolute;
  height: 2px;
  z-index: 999;
}

.noMarginTop {
  margin-top: 3px !important;
}

.isNewScheduler {
  /* right: -67px; */
  margin-top: -107px;
  width: 100%;
}

.time-now {
  background: rgb(255, 0, 0, 0.7);
  width: 100%;
  z-index: 101;
}

.booking__details {
  text-align: left;
  border: 1px solid #ffffff;
  position: absolute;
  z-index: 99;
  width: 194px;
  border-radius: 10px;
  padding: 5px;
  margin: 1px;
  color: #ffffff;
  overflow: hidden;
}

.update__booking__time--buttons > button {
  margin: 5px;
}

.searched {
  border: 5px solid rgb(255, 209, 0);
  z-index: 999;
}

.booking__details:hover {
  z-index: 999;
  border-bottom: 1px solid #c9c9c9;
  filter: brightness(1.1);
  min-height: 70px;
}

.booking__details__name {
  padding-top: 10px;
  font-size: 1rem;
  font-weight: bold;
}

.confirmation__modal-container {
  top: 0;
  left: 0;
  font-size: 16px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.54);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.confirmation__modal-content {
  padding: 20px;
  width: auto;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
}

.confirmation__modal-message {
  padding: 20px;
}

.confirmation__modal-buttons {
  margin-top: 20px;
  text-align: right;
}

.drag {
  height: 100px;
  width: 100px;
  background-color: brown;
  display: block;
}

.mobility__icon {
  float: right;
  position: absolute;
  right: 0;
  top: 0;
}
.driver-team-title-container {
  position: relative;
}

/*
.driver-team-title {
  position: relative;
} */

.driver-names {
  left: 120px;
  z-index: 9999999999;
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 10px;
  border: 1px solid rgb(224 224 224);
  min-width: 196px;
  border-radius: 8px;
}

.driver-team-title:hover + .driver-names,
.driver-names:hover {
  display: block;

  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}
.spinner .path {
  stroke: #118bf0;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

.tracking__list_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(230, 230, 230, 0.4);
  font-size: 15px !important;
}

.url {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  cursor: pointer;
  line-height: 30px;
  justify-content: space-between;
  align-items: center;
}

.copy-button {
  padding: 2px 10px;
  border: 1px solid #118bf0;
  width: 128px;
  border-radius: 5px;
  color: #118bf0;
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
}

.traciking__list_madal_content {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  max-width: 500px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.traciking__list_madal_button {
  background-color: #118bf0;
  border: 1px solid #118bf0;
  color: #fff;
  font-size: 15px !important;
}

.traciking__list_madal_button:hover {
  background-color: #118bf0;
  border: 1px solid #118bf0;
  color: #fff;
  filter: brightness(0.9);
}

.heat__map_container {
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  top: -1px;
  left: -1px;
  height: inherit;
  width: inherit;
  font-size: 12px;
}

.heat__map_top {
  height: 50%;
  width: 100%;
  background-color: #118bf0;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.54);
  text-align: center;
  padding: 5px;
  color: #000;
  text-shadow: 2px 3px 5px rgba(255, 255, 255, 0.7);
}

.heat__map_bottom {
  float: right;
  height: 50%;
  width: 100%;
  background-color: #000;
  text-align: center;
  padding: 5px;
  color: #000;
  text-shadow: 2px 3px 5px rgba(255, 255, 255, 0.7);
}

.whiteBG {
  top: 0;
  position: absolute;
  background-color: #fff;
  opacity: 0.9;
  height: 100%;
  width: 100%;
  z-index: -1;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.touch-area {
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: grab;
  z-index: 999;

  top: 0;
  left: 0;
}

.touch-highlight {
  background: rgb(17, 139, 240);
  color: #fff;
  font-weight: bold;
}

.touch-highlight > .add-driver,
.touch-highlight > span {
  color: #fff !important;
}

@media only screen and (max-width: 520px) {
  .url {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .copy-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .copy-button > div {
    margin-right: 4px;
  }
}
