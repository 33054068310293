.dropdown__check_list {
  display: inline-block;
  width: 100%;
}

.dropdown__check_list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 5px 5px 10px;
  border: 1px solid #ccc;
}

.dropdown__check_list .anchor:after {
  position: absolute;
  content: '';
  border-left: 2px solid black;
  border-top: 2px solid black;
  padding: 5px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dropdown__check_list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown__check_list ul.items {
  padding: 2px;
  display: none;
  margin: 3px 0 0 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  min-height: 150px;
  position: absolute;
  background: #f1f1f1;
  width: 100%;
  z-index: 100;

  box-shadow: 0 10px 35px -5px rgba(0, 0, 0, 0.15);
}

.dropdown__check_list ul.items li {
  list-style: none;
  display: flex;
  align-items: center;
  padding-left: 10px;
  line-height: 25px;
  font-size: 14px;
  min-height: 35px;
  text-transform: capitalize;
}

.dropdown__check_list ul.items li:hover,
.selected__value {
  background-color: #118bf0;
  color: #fff;
  cursor: pointer;
}

.dropdown__check_list ul.items .disabled:hover {
  background: #f1f1f1;
  color: #4a525f;
}

.dropdown__check_list.visible .items {
  display: block;
}

.dropdown__check_list ul.items li input {
  margin-right: 8px;
}

.custom__checkbox_override {
  height: 18px;
  width: 18px;
  cursor: pointer;
  -moz-appearance: button !important;
  -webkit-appearance: button !important;
  -o-appearance: button !important;
  z-index: unset;
  position: relative !important;
  border: 1px solid #98a6ad;
  border-radius: 3px;
}

.input-icon {
  float: right;
  position: absolute;
  margin: auto;
  right: 8px;
  height: 40px;
  cursor: pointer;

  font-size: 22px;
}

.loading {
  display: flex;
  justify-content: center;
}

.time__dropdown {
  color: #4a525f;
  font-size: 14px;
  font-weight: 400;
}

.list__footer {
  color: rgb(17, 139, 240);
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  display: flex !important;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 99999;
  top: 185px;
  cursor: pointer;
  width: 100%;
  z-index: 999;
  background: #f1f1f1;
  height: 30px;
  border: 0px 1px 1px 1px;
  align-items: center;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  display: none;
}
