.loading {
  position: fixed;
  z-index: 99999;
  width: 100vw !important;
  height: 100vh;
  top:0;
  left: 0;
  width: 0;
  display: flex;
  align-items: center;
  justify-content: center;  
  background: rgba(255, 255, 255, 0.9);
}
.loading >div {
  margin-top: 100px;
}