.header {
  color: var(--dark-grey, #4a525f);
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.content {
  color: var(--dark-grey, #4a525f);
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.driverInfoContainer {
  left: 120px;
  z-index: 99999999999999;
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 20px;
  border: 1px solid rgb(224, 224, 224);
  min-width: 296px;
  border-radius: 5px;
  border: 1px solid var(--light-blue, #d7dce5);
  -webkit-box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.15);
}

.row {
  text-align: left;
  display: flex;
  margin-top: 10px;
  .bold {
    font-weight: 600;
  }
}

.row:first-child {
  margin-top: 0;
}

.img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: solid 1px #d7dce5;

  img {
    border-radius: 50%;
    object-fit: cover;
    height: inherit;
    width: inherit;
  }
}
